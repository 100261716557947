<template>
  <div
    class="shop-product-card-content"
    :class="{ 'is-tip': item.type === 'tip' }"
  >
    <div class="shop-product-card-content__inner">
      <div
        v-if="item.type === 'tip' && item.logo"
        class="shop-product-card__tip-logo"
      >
        <AtomImage :src="item.logo" />
      </div>

      <ShopProductCardLocation
        v-if="item.location?.name && item.type !== 'event' && item.type !== 'consultation'"
      />

      <ShopProductCardSpeaker
        v-if="item.speaker?.name && item.type !== 'event' && item.type !== 'consultation' && !item.location?.name"
      />

      <ShopProductCardPrice
        v-if="item.type === 'product' || item.type === 'event'"
      />

      <div v-if="item.type === 'blog'" class="shop-product-card__created">
        {{ dayjs(item.createdAt).format('MMMM YYYY') }}
      </div>

      <div class="shop-product-card__name">
        {{ item.title }}
      </div>

      <div v-if="item.text" class="shop-product-card__text">
        {{ item.text }}
      </div>

      <div v-if="item.tip" class="shop-product-card__tip">
        {{ item.tip.description || item.tip }}
      </div>

      <div
        v-if="item.brand?.sb_slug || item.manufacturer?.sb_slug"
        class="shop-product-card__brand"
        @click="navigateToBrand($event)"
        @keypress="navigateToBrand($event)"
      >
        {{ useTranslation('general', 'of') }}
        <span>{{ item.brand?.name || item.manufacturer?.name }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';

const item = inject('item');

const navigateToBrand = ($event) => {
    $event.stopPropagation();
    navigateTo(`../${item.brand?.sb_slug || item.manufacturer?.sb_slug}`);
};
</script>

<style lang="scss" scoped>
.shop-product-card-content {
    margin-top: 13px;
}

.shop-product-card-content__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.shop-product-card__name, .shop-product-card__brand, .shop-product-card__created {
    @include typo-font('regular');
}

.shop-product-card__created {
    @include typo-size('lead');
    width: 100%;
}

.shop-product-card__text, .shop-product-card__tip {
    @include typo-font('light');
    width: 100%;
    font-size: 20px;
}

.shop-product-card__tip {
    margin-top: 8px;
}

.shop-product-card__name {
    @include typo-size('p');
    @include fluid('line-height', 18px, 25px);
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
}
.shop-product-card__brand {
    @include typo-size('a');

    span {
        color: $C_PRIMARY;
    }
}

.shop-product-card__tip-logo {
    @include fluid('height', 40px, 59px);
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    .atom-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 89px;
        @include fluid('height', 40px, 59px);
    }
}
</style>
